import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SeasonManagerService } from '@services/season-manager.service';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'admin-header',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule, ButtonModule],
  templateUrl: './header.html',
})
export class HeaderComponent {
  @Input() public showSeasons = true;

  public season$ = this.seasonManager.season$;
  public seasons = this.seasonManager.availableSeasons();

  constructor(
    private seasonManager: SeasonManagerService,
  ) {}

  changeSeason(season: string) {
    this.seasonManager.setSeason(season);
  }
}
